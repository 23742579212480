<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="activityEdit">
		<div class="activityEdit-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input maxlength="15" class="common-row-ipt" placeholder="请输入"
						v-model="activityForm.activityName" />
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动分类</span>
					<div class="common-row-tablist">
						<span style="margin-bottom: 0;" @click="tabSelect(item)" v-for="(item, index) in classlList"
							:key="index" :class="{'tabitem_active': activityForm.activityType == item.type}"
							class="common-row-tabitem">{{item.value}}</span>
					</div>
				</div>
			</div>
			<div class="activityEdit-timeAreas">
				<div class="activityEdit-timeAreas-content">
					<div class="common-row">
						<div class="common-row-item">
							<span class="common-row-label">开始时间</span>
							<el-date-picker class="common-row-date" value-format="yyyy-MM-dd"
								v-model="activityForm.starTime" type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
						<span style="font-size: 14px;font-family: PingFang SC;font-weight: 400;margin: 0 10px;">~</span>
						<div class="common-row-item">
							<span class="common-row-label">结束时间</span>
							<el-date-picker class="common-row-date" value-format="yyyy-MM-dd"
								v-model="activityForm.endTime" type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
					</div>
					<div class="common-row">
						<div class="common-row-item">
							<span class="common-row-label">活动地址</span>
							<div style="display: flex;align-items: center;width: 100%;">
								<el-input class="common-row-ipt" placeholder="请输入" v-model="activityForm.address" />

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="activityEdit-editor">
				<div class="activityEdit-editor-title">
					活动详情
				</div>
				<quillEditor @editorChange="editorChange" :intEditor.sync="activityForm.activityDetails"
					class="handle_form_editor" />
			</div>
			<div class="flex-item" style="margin-top: 80px;">
				<el-button v-preventReClick @click="submitVerify" type="primary">提交审核</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import quillEditor from "@/components/quillEditor.vue";
	import {
		getActivityDetail,
		editActivity,
	} from "@/api/activity";
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				activityForm: {
					activityType: "1",
					organizer: "总会",
					activityName: "",
					activityDetails: "",
					address: "",
					starTime: "",
					endTime: "",
				},
				activityId: undefined,
				classlList: [{
						type: "1",
						value: "A类"
					},
					{
						type: "3",
						value: "B类"
					},
				],
			};
		},
		created() {
			this.activityId = this.$route.query.activityId
			this.activityDetailFn()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			tabSelect(item) {
				this.activityForm.activityType = item.type
			},
			editorChange(props) {
				console.log("editorChange", props)
				this.activityForm.activityDetails = props
			},
			activityEditressFn(type, index, cindex) {
				switch (type) {
					case "addP":
						let obj = {
							activityAdress: [{
								address: ""
							}],
							activityTimeStart: "",
							activityTimeEnd: ""
						}
						this.activityForm.adressList.push(obj)
						break;
					case "addC":
						let objC = {
							address: ""
						}
						this.activityForm.adressList[index].activityAdress.push(objC)
						break;
					case "deleteP":
						if (this.activityForm.adressList.length == 1) {
							this.$message.error("已经是最后一个了~")
						} else {
							this.activityForm.adressList.splice(index, 1)
						}
						break;
					case "deleteC":
						if (this.activityForm.adressList[index].activityAdress.length == 1) {
							this.$message.error("已经是最后一个了~")
						} else {
							this.activityForm.adressList[index].activityAdress.splice(cindex, 1)
						}
						break;
				}
			},
			// 获取活动详情
			activityDetailFn() {
				getActivityDetail(this.activityId, {
					id: this.activityId
				}).then((res) => {
					if (res.code == 0) {
						this.activityForm = res.data
					}
				})
			},
			submitVerify() {
				let activityData = JSON.parse(JSON.stringify(this.activityForm))
				console.log("activityData", activityData)
				if (!activityData.activityName) {
					this.$message.error("请填写活动名称~")
					return
				}
				if (!activityData.starTime || !activityData.endTime) {
					this.$message.error("请选择活动时间~")
					return
				} else {
					if (new Date(activityData.starTime).getTime() > new Date(activityData.endTime).getTime()) {
						this.$message.error("活动开始时间必须小于活动结束时间~")
						return
					}
				}
				if (!activityData.address) {
					this.$message.error("请填写活动地址~")
					return
				}
				if (!activityData.activityDetails) {
					this.$message.error("请填写活动详情~")
					return
				}
				if(activityData.activityStatus == 0) {
					activityData.activityStatus = 1
				}
				editActivity(activityData.id, activityData).then((res) => {
					if (res.code == 0) {
						this.$message.success("编辑一般活动成功~")
						this.$router.go(-1)
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	.flex-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.activityEdit {
		.activityEdit-content {
			padding: 20px 20px;

			.common-row {
				margin-bottom: 20px;

				.common-row-tablist {
					flex: 1;
					margin-left: 10px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.common-row-tabitem {
						min-width: 120px;
						height: 36px;
						line-height: 36px;
						background: #FFFFFF;
						border: 1px solid #EDF1F7;
						border-radius: 2px;
						text-align: center;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #303133;
						cursor: pointer;
						box-sizing: border-box;
						margin: 0 10px 8px 0;

						&.tabitem_active {
							background: rgba(28, 171, 185, 0.1);
							border: 1px solid $theme_color;
							color: $theme_color;
						}
					}
				}

				.common-row-tablist {
					flex: 1;
					margin-left: 10px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.common-row-tabitem {
						min-width: 120px;
						height: 36px;
						line-height: 36px;
						background: #FFFFFF;
						border: 1px solid #EDF1F7;
						border-radius: 2px;
						text-align: center;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #303133;
						cursor: pointer;
						box-sizing: border-box;
						margin: 0 10px 8px 0;

						&.tabitem_active {
							background: rgba(28, 171, 185, 0.1);
							border: 1px solid $theme_color;
							color: $theme_color;
						}
					}
				}

				.common-row-delete {
					width: 124px;
					height: 36px;
					border: 1px solid #EDF1F7;
					border-radius: 0px 2px 2px 0px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					margin-left: 10px;

					img {
						width: 18px;
						height: 18px;
						margin-bottom: 4px;
						margin-right: 8px;
					}

					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #303133;
					}
				}

				.common-row-ipt {
					flex: 1;
				}

				.common-row-date {
					width: 100%;
				}
			}

			.activityEdit-timeAreas {
				display: flex;
				align-items: stretch;

				.activityEdit-timeAreas-content {
					flex: 1;
					display: flex;
					flex-direction: column;
				}

				.activityAdd-timeAreas-delete {
					width: 128px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					margin-left: 5px;
					cursor: pointer;
					border: 1px solid #EDF1F7;
					border-radius: 0px 2px 2px 0px;
					margin-bottom: 20px;

					.timeAreas-delete-item {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						img {
							width: 18px;
							height: 18px;
							margin-bottom: 4px;
						}

						span {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 20px;
							color: #303133;
						}
					}
				}
			}

			.activityEdit-btn {
				width: 100%;
				height: 36px;
				border: 1px solid #EDF1F7;
				border-radius: 0px 2px 2px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #1CABB9;
				margin: 0 0 16px 0;
				cursor: pointer;
			}

			.activityEdit-editor {
				display: flex;
				flex-direction: column;

				.activityEdit-editor-title {
					background: #F6F7F9;
					border: 1px solid #EDF1F7;
					border-radius: 2px 2px 0px 0px;
					line-height: 36px;
					padding: 0 22px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #606266;
				}
			}

		}
	}
</style>
